






















import { PatientClient } from "@/api/api";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({})
export default class ConfirmEmail extends Vue {
  @Prop()
  token!: string;

  loading = false;
  valid: boolean | null = null;
  error = "";
  api = new PatientClient();

  mounted() {
    this.loading = true;
    this.api
      .confirmemail({ token: this.token })
      .then((valid) => {
        this.valid = valid;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        this.error = error;
      });
  }
}
